.loadingScreen {
  background-color: #c0ffee;
  height: 120px;
  width: 300px;
  padding: 30px;
  text-align: center;
  font-size: 16px;
  border-radius: 10px;
  border: 2px solid black;
}
