/* TODO: Take image of ready client and add it as background image when
no backend collection can be made */

body {
  background-color: #696969;
  margin: 0;
  overflow: hidden;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

/* generic css here */

button {
  border: 2px solid black;
  height: 30px;
  border-radius: 3px;
  color: black;
  background: transparent;
  transition: background 200ms;
  cursor: pointer;
}
button:hover {
  background: #bbb;
}

/* thing in center of screen */
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}