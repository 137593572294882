.button-base {
  height: 25px;
  width: 100px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
}

.button-enabled {
  border: 2px solid black;
  background-color: red;
}

.button-enabled:hover {
  background-color: #ffcccb;
}

.button-disabled {
  border: 2px solid black;
  background-color: green;
}

.button-disabled:hover {
  background-color: lightgreen;
}
