.menu {
  background-color: white;
  color: black;
  height: 150px;
  width: 300px;
  text-align: center;
  border-radius: 10px;
  border: 2px solid black;
  padding: 15px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

input[type="text"]{
  width: 250px;
  border: 1px solid black;
}

.buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

button {
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.sample{
  background-color: green;
  z-index: 3;
  height: 18px;
  width: 18px;
  position: absolute;
  right: 100px;
  text-indent: -100px
}

.offset {
  margin: 20px;
}

.colorMakerSelect {
  display: inline-block;
}

.colorMakerSelect label {
  width: 20px;
  height: 20px;
  margin-left: -6px;
  display: inline-block;
  text-indent: -100px;
}

.colorMakerSelect label:hover {
  cursor: pointer;
}

.colorMakerSelect input[type=radio] {
  visibility: hidden;
  width: 0;
}

.colorMakerSelect input[type=radio]:checked + label {
  outline: 2px solid black;
}
