/* TODO: Center both message and warning perfectly,
scale div according to text length*/

.textcontent {
  margin: 20px;
  color: white;
  font-size: 110%;
}

.adminBox {
  position: fixed;
  left: 0;
  top: 0;
  width: 10vw;
  /* height: 30vh; */
  border: 2px solid black;
  border-radius: 4px;
  background-color: darkgrey;
  text-align: center;
  color: var(--main-text-color);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}

.adminBox p {
  margin: 0;
  margin-bottom: 15px;
}

.adminBox button {
  margin: 10px;
}

.OkButton {
  margin-top: 5px;
  color: black;
  background-color: white;
}