.colorSelect {
  display: flex;
  align-items: center;
}

.colorSelect label {
  width: 1.5em;
  height: 1.5em;
  border: 1px solid black;
  border-radius: 5px;
  margin: 3px;
}

.colorSelect input[type=checkbox]:checked + label {
  border-width: 3px;
  border-color: black;
}

.colorSelect label:hover {
  cursor: pointer;
}

.colorSelect input[type=checkbox] {
  display: none;
}
